import { DateTime } from 'luxon';
import { handle } from 'redux-pack';
import { produce } from 'immer';
import omit from 'lodash/omit';

import {
  ARCHIVE_STORY,
  UNARCHIVE_STORY,
  RECEIVE_STORY_SHARE_DELETION,
  GET_STORY_SHARES,
  ADD_STORY_SHARE,
  UPDATE_STORY_SHARE,
  REMOVE_STORY_SHARE,
  MAKE_PUBLIC_STORY_SHARE,
} from '../story-shares/types';
import {
  CREATE_STORY_MEDIUM_COVER,
  CREATE_STORY_MEDIUM,
  CREATE_TEXT_MEDIUM,
  UPDATE_TEXT_MEDIUM,
  DESTROY_STORY_MEDIUM,
  // UPLOAD_MEDIUM_FILE,
  UPLOAD_MEDIUM_SUCCESS,
  UPLOAD_MEDIUM_START,
  // UPLOAD_MEDIUM_PROGRESS,
  RECEIVE_STORY_MEDIUM,
  CREATE_MEDIUM,
} from '../story-media/types';
import {
  CREATE_STORY_PAGE,
  LINK_STORY_PAGES,
  UNLINK_STORY_PAGES,
  MOVE_STORY_PAGE,
} from '../story-pages/types';
import { GET_NOTIFICATIONS, RECEIVE_NOTIFICATION } from '../notifications/types';
import { LOGOUT } from '../auth/types';
import { RECEIVE_ENTITY_DELETION, RECEIVE_ENTITY, UPDATE_ENTITY, REQUEST_ENTITIES } from './types';
import {
  UPDATE_MEMBER_AVATAR,
  GET_MEMBER_IDENTIFIERS,
  DESTROY_IDENTIFIER,
  GET_PUBLIC_MEMBER,
  GET_CURRENT_USER,
  POST_MEMBER_EVENT,
  GET_MEMBER_EVENTS,
} from '../members/types';
import { mergeEntities } from './helper';
import { sortPages } from './pages';
import * as storyTypes from '../stories/types';

const createInitialState = keys => keys.sort().reduce((acc, key) => ({ ...acc, [key]: {} }), {});

const initialState = createInitialState([
  'invitations',
  'connections',
  'collections',
  'suggestions',
  'members',
  'stories',
  'activities',
  'story-shares',
  'story-exports',
  'identifiers',
  'member-settings',
  'pages',
  'comments',
  'likes',
  'memoir-shares',
  'shares',
  'memoirs',
  'how-do-i-collections',
  'how-do-i-collection-shares',
  'images',
  'videos',
  'audios',
  'external-media',
  'tracker-tracker-events',
  'tracker-event-topics',
  'child-journals',
  'tags',
  'family-journals',
  'family-journal-shares',
  'child-journal-shares',
  'personal-journals',
  'personal-journal-shares',
  'personal-journal-collections',
  'family-journal-collections',
  'child-journal-collections',
  'other-journals',
  'other-journal-shares',
  'other-journal-collections',
  'story-requests',
  'story-request-shares',
  'milestones',
  'milestone-shares',
  'story-export',
  'features',
  'pricing-plans',
  'subscriptions',
  'products',
  'product-groups',
]);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // remove entities
    case REMOVE_STORY_SHARE:
      return handle(state, action, {
        success: prevState => {
          const { [action.meta.shareId]: _share, ...nextShares } = prevState['story-shares'];
          return {
            ...prevState,
            'story-shares': { ...nextShares },
          };
        },
      });
    // entities from axios already normalized
    case storyTypes.UPDATE_MEDIUM_ACTIVITY:
    case storyTypes.GET_PUBLIC_STORY:
    case ARCHIVE_STORY:
    case UNARCHIVE_STORY:
    case storyTypes.EXPORT_STORY:
    case storyTypes.GET_STORY:
    case storyTypes.SHARE_STORY:
    case GET_CURRENT_USER:
    case GET_NOTIFICATIONS:
    case ADD_STORY_SHARE:
    case UPDATE_STORY_SHARE:
    case GET_STORY_SHARES:
    case MAKE_PUBLIC_STORY_SHARE:
    case GET_MEMBER_IDENTIFIERS:
    case GET_PUBLIC_MEMBER:
    case GET_MEMBER_EVENTS:
    case POST_MEMBER_EVENT:
    case REQUEST_ENTITIES:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          if (payload.status === 204) {
            return prevState;
          }
          if (!payload.data) {
            return prevState;
          }
          const nextEntities = mergeEntities(payload.data, prevState);
          return nextEntities;
        },
      });
    // normalized entities received not via redux-pack
    case RECEIVE_ENTITY: {
      if (!payload) return state;
      if (payload.status === 204) {
        return state;
      }
      if (!payload.data) {
        return state;
      }
      const nextEntities = mergeEntities(payload.data, state);
      return nextEntities;
    }
    case RECEIVE_STORY_SHARE_DELETION: {
      const storyShareId = payload.config.url.split('/').slice(-1)[0];
      const { [storyShareId]: _, ...nextShares } = state['story-shares'];
      return {
        ...state,
        'story-shares': nextShares,
      };
    }
    case DESTROY_IDENTIFIER:
      return handle(state, action, {
        success: prevState => {
          const { [action.meta.identifierId]: _identifier, ...identifiers } = prevState.identifiers;
          return { ...prevState, identifiers };
        },
      });
    case storyTypes.DESTROY_STORY:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { storyId } = action.meta;
          // remove the story entity
          const { [storyId]: storyToDelete, ...stories } = prevState.stories;
          // remove the story shares
          const shareIdsToDelete = (storyToDelete['story-share'] || []).map(share => share.id);

          const nextStoryShares = Object.keys(prevState['story-shares']).reduce((acc, el) => {
            if (!shareIdsToDelete.includes(el)) {
              return {
                ...acc,
                [el]: prevState['story-shares'][el],
              };
            }
            return acc;
          }, {});

          return {
            ...prevState,
            'story-shares': nextStoryShares,
            stories,
          };
        },
      });
    case UPDATE_MEMBER_AVATAR:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { result, entities } = payload.data;
          const nextImage = entities.images[result.images[0]];
          nextImage.tmpAvatar = action.meta.fileUrl;

          return {
            ...prevState,
            images: {
              ...prevState.images,
              [nextImage.id]: nextImage,
            },
            members: {
              ...prevState.members,
              [action.meta.memberId]: {
                ...prevState.members[action.meta.memberId],
                'avatar-image': {
                  type: 'images',
                  id: payload.data.result.images[0],
                },
              },
            },
          };
        },
      });
    case storyTypes.ADD_MEDIUM_ACTIVITY:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { mediumId, mediumType, mediumActivity, storyId } = action.meta;
          const { data } = payload;

          // merge entiries
          let nextState = mergeEntities(data, prevState);

          // add medium activity to medium
          const { [mediumId]: medium } = prevState[mediumType];
          const activityType = Object.keys(data.result)
            .filter(key => key !== 'members')
            .join();
          medium['media-activities'] = [
            ...medium['media-activities'],
            {
              id: data.result[activityType][0],
              type: activityType,
            },
          ];

          // merge updated medium into entities
          nextState = {
            ...nextState,
            [mediumType]: {
              ...nextState[mediumType],
              [mediumId]: medium,
            },
            stories: {
              ...nextState.stories,
              [storyId]: {
                ...nextState.stories[storyId],
                [mediumActivity]: nextState.stories[storyId][mediumActivity] + 1,
              },
            },
          };
          return nextState;
        },
      });
    case storyTypes.REMOVE_MEDIUM_ACTIVITY:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { mediumActivityType, mediumActivityId, storyId } = action.meta;
          const activityType = `${mediumActivityType.slice(0, -1)}-count`;

          const nextMediaActivities = {
            [mediumActivityType]: omit(prevState[mediumActivityType], mediumActivityId),
          };

          const nextStories = {
            stories: {
              ...prevState.stories,
              [storyId]: {
                ...prevState.stories[storyId],
                [activityType]: prevState.stories[storyId][activityType] - 1,
              },
            },
          };

          const nextState = {
            ...prevState,
            // remove medium activity from entities
            ...nextMediaActivities,
            // remove medium activity from medium
            ...nextStories,
          };

          return nextState;
        },
      });
    case RECEIVE_STORY_MEDIUM: {
      const { storyId, tmpUrl } = action;
      // add new medium to media
      const nextState = mergeEntities(payload.data, state);
      // select parent
      const nextStory = nextState.stories[storyId];
      // select new medium
      const { result, entities } = payload.data;
      const mediumType = Object.keys(entities).filter(key => key !== 'pages')[0];
      const { [result[mediumType][0]]: medium } = nextState[mediumType];
      medium.tmpUrl = tmpUrl;
      medium.userNewUpload = true;

      // set temporary file to be uploaded
      const newPage = payload.data.entities.pages[payload.data.result.pages[0]];
      if (nextStory && nextStory.pages) {
        nextStory.pages = sortPages(
          [...nextStory.pages, { id: newPage.id, type: 'pages' }],
          nextState
        );
        nextState.stories[storyId] = nextStory;
      }

      return nextState;
    }
    case CREATE_STORY_MEDIUM_COVER:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          // add new medium to media
          const nextState = mergeEntities(payload.data, prevState);
          // select parent
          const { storyId } = action.meta;
          const nextStory = nextState.stories[storyId];
          // select new medium
          const { [payload.data.result.images[0]]: image } = nextState.images;
          image.tmpUrl = action.meta.tmpUrl;
          // set temporary file to be uploaded
          nextStory['cover-image'] = { id: payload.data.result.images[0], type: 'images' };

          return nextState;
        },
      });
    case CREATE_STORY_MEDIUM:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { storyId } = action.meta;
          const nextState = mergeEntities(payload.data, prevState);
          const nextStory = nextState.stories[storyId];
          const newPage = payload.data.entities.pages[payload.data.result.pages[0]];
          if (action.meta.tmpUrl) {
            nextState[newPage.media[0].type][newPage.media[0].id].tmpUrl = action.meta.tmpUrl;
            nextState[newPage.media[0].type][newPage.media[0].id].userNewUpload = true;
          }
          if (nextStory && nextStory.pages) {
            nextStory.pages = sortPages(
              [...nextStory.pages, { id: newPage.id, type: 'pages' }],
              nextState
            );
            nextState.stories[nextStory.id] = nextStory;
          }

          return nextState;
        },
      });
    case CREATE_TEXT_MEDIUM:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { storyId } = action.meta;
          const nextState = mergeEntities(payload.data, prevState);
          const nextStory = nextState.stories[storyId];
          const newPage = payload.data.entities.pages[payload.data.result.pages[0]];
          if (nextStory && nextStory.pages) {
            nextStory.pages = sortPages(
              [...nextStory.pages, { id: newPage.id, type: 'pages' }],
              nextState
            );
            nextState.stories[nextStory.id] = nextStory;
          }
          return nextState;
        },
      });
    case DESTROY_STORY_MEDIUM:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const destroyedMediumId = payload.config.url.split('/').reverse()[0];
          const { [action.meta.pageId]: nextPage } = prevState.pages;
          const { [action.meta.storyId]: nextStory } = prevState.stories;

          // remove medium from entities
          const nextMedia = omit(prevState[action.meta.mediumType], destroyedMediumId);

          // remove medium from page media
          nextPage.media = nextPage.media.filter(medium => medium.id !== destroyedMediumId);

          // if the page is empty now, remove it from the story
          if (!nextPage.media.length) {
            nextStory.pages = nextStory.pages.filter(page => page.id !== nextPage.id);
          }

          return {
            ...prevState,
            [action.meta.mediumType]: nextMedia,
            pages: {
              ...prevState.pages,
              [nextPage.id]: nextPage,
            },
            stories: {
              ...prevState.stories,
              [nextStory.id]: nextStory,
            },
          };
        },
      });
    case CREATE_STORY_PAGE:
    case UPDATE_TEXT_MEDIUM:
      return handle(state, action, {
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const nextState = mergeEntities(payload.data, prevState);
          return nextState;
        },
      });
    case MOVE_STORY_PAGE:
      return handle(state, action, {
        start: prev => {
          const { pageId, position } = action.meta;
          const pages = {
            ...prev.pages,
            [pageId]: {
              ...prev.pages[pageId],
              'updated-at': DateTime.local().toISO(),
              position,
            },
          };
          return mergeEntities({ entities: { pages } }, prev);
        },
        success: prev => mergeEntities(payload.data, prev),
      });
    case UNLINK_STORY_PAGES:
      return handle(state, action, {
        start: prev => {
          const { storyId, fromPage, destPageValues } = action.meta;
          // create temporary page
          const splitMediaIds = destPageValues.media.map(el => el.id);
          const pages = {
            ...prev.pages,
            [fromPage]: {
              ...prev.pages[fromPage],
              media: prev.pages[fromPage].media.filter(el => !splitMediaIds.includes(el.id)),
            },
            tempPage: {
              id: 'tempPage',
              'updated-at': DateTime.local().toISO(),
              ...destPageValues,
            },
          };
          // update story pages list
          const stories = {
            ...prev.stories,
            [storyId]: {
              ...prev.stories[storyId],
              pages: [...prev.stories[storyId].pages, { type: 'pages', id: 'tempPage' }],
            },
          };
          return mergeEntities({ entities: { stories, pages } }, prev);
        },
        failure: prev => {
          const { storyId, fromPage, destPageValues } = action.meta;
          // remove temporary page
          // revert page media to original
          const pages = {
            ...omit(prev.pages, 'tempPage'),
            [fromPage]: {
              ...prev.pages[fromPage],
              media: [...prev.pages[fromPage].media, ...destPageValues.media],
            },
          };
          // update story pages list
          const stories = {
            ...prev.stories,
            [storyId]: {
              ...prev.stories[storyId],
              pages: prev.stories[storyId].pages.filter(el => el.id !== 'tempPage'),
            },
          };
          return mergeEntities({ entities: { stories, pages } }, prev);
        },
        // success: (prev) => {
        //   // const { storyId } = action.meta;
        //   // replace temporary page with payload page
        //   const nextPrev = {
        //     ...prev,
        //     pages: omit(prev.pages, 'tempPage'),
        //     // stories: {
        //     //   ...prev.stories,
        //     //   [storyId]: {
        //     //     ...prev.stories[storyId],
        //     //     pages: prev.stories[storyId].pages.map((page) => {
        //     //       if (page.id === 'tempPage') {
        //     //         return { type: 'pages', id: payload.data.result.pages[0] };
        //     //       }
        //     //       return page;
        //     //     }),
        //     //   },
        //     // },
        //   };
        //   // update story pages list
        //   // return mergeEntities(payload.data, nextPrev);
        // },
      });
    case LINK_STORY_PAGES:
      return handle(state, action, {
        start: prev => {
          const { destPage, fromPage } = action.meta;
          const nextPagePayload = {
            entities: {
              pages: {
                [destPage.id]: {
                  ...destPage,
                  media: [...destPage.media, ...fromPage.media],
                },
                [fromPage.id]: {
                  ...fromPage,
                  media: [],
                },
              },
            },
          };
          return mergeEntities(nextPagePayload, prev);
        },
        failure: prev => {
          const { destPage, fromPage } = action.meta;
          const nextPagePayload = {
            entities: {
              pages: {
                [destPage.id]: {
                  ...destPage,
                },
                [fromPage.id]: {
                  ...fromPage,
                },
              },
            },
          };
          return mergeEntities(nextPagePayload, prev);
        },
        success: prevState => {
          const nextState = mergeEntities(payload.data, prevState);
          return nextState;
        },
      });
    case UPLOAD_MEDIUM_START: {
      const medium = state[action.meta.mediumType][action.meta.id];
      const nextMedium = { started: true, ...medium };

      return {
        ...state,
        [action.meta.mediumType]: {
          ...state[action.meta.mediumType],
          [nextMedium.id]: nextMedium,
        },
      };
    }
    case UPLOAD_MEDIUM_SUCCESS: {
      // remove tmpFile from medium
      const medium = omit(state[action.meta.mediumType][action.meta.id], [
        'started',
        'tmpFile',
        'upload-url',
      ]);

      return {
        ...state,
        [action.meta.mediumType]: {
          ...state[action.meta.mediumType],
          [medium.id]: medium,
        },
      };
    }
    case CREATE_MEDIUM:
      return handle(state, action, {
        success: prevState => {
          if (payload.status === 204) {
            return prevState;
          }
          if (!payload.data) {
            return prevState;
          }
          const { tmpUrl } = action.meta;
          const mediumType = Object.keys(payload.data.result)[0];
          const mediumId = payload.data.result[mediumType][0];
          const medium = payload.data.entities[mediumType][mediumId];
          return {
            ...prevState,
            [mediumType]: {
              ...prevState[mediumType],
              [mediumId]: {
                ...medium,
                tmpUrl,
              },
            },
          };
        },
      });
    case RECEIVE_NOTIFICATION: {
      return {
        ...state,
        activities: {
          ...state.activities,
          [payload.id]: payload,
        },
      };
    }
    case RECEIVE_ENTITY_DELETION: {
      const { [action.id]: _entity, ...nextEntities } = state[action.entityType];
      return {
        ...state,
        [action.entityType]: {
          ...nextEntities,
        },
      };
    }
    case UPDATE_ENTITY: {
      const { entityType, id, attrs } = action;
      return produce(state, draft => {
        draft[entityType][id] = {
          ...draft[entityType][id],
          ...attrs,
        };
      });
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
