import { getAuthToken } from "./api";
import { useCallback } from 'react';
import store from 'store';
import { post } from "utils/api";

const fetchToken = async ({ id, secret }) => {
  store.remove('auth_token');
  const newToken = await getAuthToken({ id, secret });
  store.set('auth_token', newToken);
};
export const useCreateNewAccount = () => {
  return useCallback(
    async (attributes = undefined) => {
      let res = null;
      if (attributes) res = await post('/v3/memoir_members', { data: { attributes } });
      else res = await post('/v3/memoir_members');
      if (res.status === 200 || res.status === 201) {
        const { id, secret } = res.data.entities.restore_token[res.data.initial.data.id]

        const token = await fetchToken({ id, secret });
        return token;
      }
      return null;
    },
    []
  );
};

export const useCreateNewV3Account = () => {
  return useCallback(
    async (attributes = undefined) => {
      let res = null;
      res = await post('/v3/accounts', attributes);
      if (res.status === 200 || res.status === 201) {
        const { id, secret } = res.data.entities.restore_token[res.data.initial.data.id]

        const token = await fetchToken({ id, secret });
        return token;
      }
      return null;
    },
    []
  );
};

export const useLogin = () => {
  return async (attributes) => {

    const loginResponse = await post(
      `/v3/login`,
      { ...attributes }
    );
    const { id, secret } = loginResponse.data?.initial.data.attributes;
    await fetchToken({ id, secret });
  };
};