import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from 'rebass';
import {
  Activity,
  LikesCommentsAvatars,
  LikesButton,
  ActivityCommentsButton,
} from './styled';
import { useToggle } from '../../app/hooks';

const ShowTranscription = ({ transcription }) => {
  const [open, toggle] = useToggle(false);

  if (!transcription) return false;

  return (
    <Activity>
      <LikesCommentsAvatars>
        <ActivityCommentsButton onClick={toggle}>
          <LikesButton style={{ fontWeight: 'bold', marginRight: 'auto' }}>
            Show Transcription
          </LikesButton>
          {open ? <FontAwesome name="chevron-up" /> : <FontAwesome name="chevron-down" />}
        </ActivityCommentsButton>
      </LikesCommentsAvatars>
      {open && (
        <Text color="#666" p="20px" pt={0}>{transcription}</Text>)
      }
    </Activity>
  );
};

ShowTranscription.propTypes = {
};

export default ShowTranscription;
