import React, { useEffect } from 'react';
import { Text, Flex } from 'rebass';
import Footer from 'stories/tell-a-story/Footer';
import { useState } from 'react';
import useEntity from 'entities/hooks/use-entity';
import { capitalizeFirstLetter } from 'stories/helper';

function Option({ isSelected, title, ...props }) {
    return <Flex
        sx={{
            width: "165px",
            height: "260px",
            padding: "0px 16px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            bg: isSelected ? '#53F' : '#fff',
            flexShrink: "0",
            borderRadius: '8px',
            border: isSelected ? 0 : "2px solid #766AB9",
            cursor: 'pointer'
        }}
        {...props}
    >
        <Text
            sx={{
                color: isSelected ? '#fff' : '#766AB9',
                textAlign: 'center',
                fontFamily: 'DM Sans',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '130%',
                letterSpacing: '-0.396px',
            }}>
            {title}
        </Text>
    </Flex >
}

const SelectContributorExperience = ({ memoirId, storyRequestData, onSubmit, isSubmitting, percentComplete }) => {
    const { memoir } = useEntity({ type: 'memoirs', id: memoirId })
    const [contributorExperience, setContributorExperience] = useState(storyRequestData?.['contributor-experience'] || null)
    useEffect(() => {
        setContributorExperience(storyRequestData?.['contributor-experience'])
    }, [storyRequestData?.['contributor-experience']])

    const title = (memoir?.['subject-relation'].toLowerCase().includes('other') || memoir?.['subject-relation'].toLowerCase().includes('friend')) ? capitalizeFirstLetter(memoir?.['subject-name']) : capitalizeFirstLetter(memoir?.['subject-relation'])
    return (
        <Flex flexDirection="column" m="auto" justifyContent="center" alignItems="left">
            <Text
                px={2}
                textAlign="left"
                fontFamily='Zilla Slab'
                fontSize={30}
                lineHeight="120%"
                letterSpacing='-0.66px'
                my={3}
            >
                Is this a question...
            </Text>
            <Flex px={2} justifyContent="space-around" >
                <Option
                    title={`For ${title}`}
                    isSelected={contributorExperience === 'memoir_subject'}
                    onClick={() => setContributorExperience('memoir_subject')}
                    mr={2}
                />
                <Option
                    title={`For others about ${title}`}
                    isSelected={contributorExperience === 'other_contributors'}
                    onClick={() => setContributorExperience('other_contributors')}
                />
            </Flex>

            <Footer
                disabled={!contributorExperience || isSubmitting}
                onNextPress={() => {
                    const attributes = {
                        "contributor-experience": contributorExperience,
                    }
                    onSubmit(attributes)
                }}
                percentComplete={percentComplete}
                nextButtonText="Next"
            />
        </Flex>
    );
};

export default SelectContributorExperience;
